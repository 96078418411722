.counter-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.counter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.counter-wrapper svg {
    width: 45px;
}

.counter-wrapper span {
    color: #fff;
    font-weight: 700;
    font-size: 55px;
}

.menuButton {
    background: none;
    padding: 0;
    border: 0;
    position: absolute;
    left: 10px;
    top: calc(50% - 19px)
}