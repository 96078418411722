.tapping-area {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.taps-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    height: calc(var(--tg-viewport-stable-height) - 330px);
    width: 100%;
    background-color: transparent;
    border: 0;
    position: relative;
}

.taps-button > div > svg {
    width: 300px !important;
}
.taps-button > div > svg > g {
    width: 300px !important;
}


.taps-button img {
    object-fit: cover;
    height: 100%;
}

.overlay-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, #EB8E13,#eb8d1383 58.85%,rgba(13,85,144,0));
    height: 70px;
}

.overlay-image-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, #EB8E13,#eb8d1383 58.85%,rgba(13,85,144,0));
    height: 70px;
    z-index: 2;
}

.floating-number {
    position: absolute;
    color: #fff;
    font-size: 26px;
    pointer-events: none;
    z-index: 999999;
  }