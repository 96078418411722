.page-welcome
    display: flex
    flex-direction: column
    height: 100%
    justify-content: space-between
    color: #fff
    padding: 20px 0
    h1
        text-align: center
        font-size: 46px
        margin: 20px 0
    p
        text-align: center
        font-size: 20px
        margin: 0

.page-welcome-body
    padding-top: 20px

.username-input, .wallet-input
    display: flex
    flex-direction: column
    gap: 5px
    padding-top: 20px

.inner-username-input
    display: flex
    flex-direction: column
    width: calc(100% - 24px)
    background: #CD7000
    border: solid 1px #FAB10D
    border-radius: 12px
    padding: 10px
    gap: 3px
    position: relative
    min-height: 32px
    span
        font-size: 12px
    input
        background: transparent
        border: 0
        color: #fff
        font-size: 18px
    button
        background: #FCECAE
        border: 0
        border-radius: 10px
        font-size: 16px
        padding: 8px 20px
        color: #EB8E13
        text-shadow: 0px 5px 5px #eb8d1357
        position: absolute
        right: 8px
        top: 8px

.inner-wallet-input
    display: flex
    flex-direction: row
    justify-content: space-between
    width: calc(100% - 24px)
    min-height: 32px
    background: #CD7000
    border: solid 1px #FAB10D
    border-radius: 12px
    padding: 10px
    gap: 3px
    position: relative
    &> div
        display: flex
        justify-content: center
        align-items: center
        gap: 10px
        font-size: 16px
    button
        background: #FCECAE
        border: 0
        border-radius: 10px
        font-size: 16px
        padding: 8px 20px
        color: #EB8E13
        text-shadow: 0px 5px 5px #eb8d1357
        position: absolute
        right: 8px
        top: 8px
        
    span
        font-size: 12px

.lets-play-button
    background: #FCECAE
    border: 0
    height: 64px
    border-radius: 10px
    font-size: 24px
    padding: 0 15px
    color: #EB8E13
    text-shadow: 0px 5px 5px #eb8d1357
    display: flex
    justify-content: center
    align-items: center
    text-decoration: none
    width: 100%
    margin-top: 20px


.loadingArea
    display: flex
    height: 600px
    width: 100%
    gap: 20px
    flex-direction: column
    justify-content: center
    align-items: center
    span
        font-size: 26px
        color: #fff