#root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  background: #EB8E13;
  overflow: hidden;
  height: 100%;
  max-height: var(--tg-viewport-stable-height);
}

.page-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px
}

.page-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(var(--tg-viewport-stable-height) - 180px);
  overflow-y: auto;
  overflow-x: hidden
}

a {
  -webkit-tap-highlight-color: transparent;
}

* {  
  -webkit-backface-visibility:  hidden;
  -webkit-tap-highlight-color:  transparent;
}