.page-profile
    display: flex
    flex-direction: column
    height: 100%
    justify-content: start
    color: #fff
    padding: 20px 0
    h1
        text-align: center
        font-size: 46px
        margin: 20px 0
    p
        text-align: center
        font-size: 20px
        margin: 0

.page-profile-body
    padding-top: 20px
    .username-input, .wallet-input
        display: flex
        flex-direction: column
        gap: 5px
        padding-top: 5px
    .inner-username-input
        display: flex
        flex-direction: column
        width: calc(100% - 24px)
        background: #CD7000
        border: solid 1px #FAB10D
        border-radius: 12px
        padding: 10px
        gap: 3px
        span
            font-size: 12px
        input
            background: transparent
            border: 0
            color: #fff
            font-size: 18px
    .inner-wallet-input
        display: flex
        flex-direction: column
        justify-content: start
        width: calc(100% - 24px)
        min-height: 32px
        background: #CD7000
        border: solid 1px #FAB10D
        border-radius: 12px
        padding: 10px
        gap: 3px
        height: 40px
        position: relative
        &> div
            display: flex
            justify-content: start
            gap: 10px
            font-size: 16px
        button
            background: #FCECAE
            border: 0
            border-radius: 10px
            font-size: 16px
            padding: 6px 20px
            color: #EB8E13
            text-shadow: 0px 5px 5px #eb8d1357
            position: absolute
            display: flex
            align-items: center
            justify-content: center
            right: 14px
            top: 14px
            height: 32px
            min-width: 90px
        span
            font-size: 12px
    input
        background: transparent
        border: 0
        color: #fff
        font-size: 14px
        width: 100%

.titleProfile
    display: block
    font-size: 20px
    margin-bottom: 10px

.wallet-input
    p
        font-size: 14px
