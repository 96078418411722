.menu-container {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    background-color: #FCECAE;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 0 #CD7000;
    margin-top: 5px;
}

.menu-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(235, 142, 19, 0.2);
    border: solid 1px rgba(235, 142, 19, 1);
    width: calc(20% - 5px);
    border-radius: 12px;
    padding: 5px 0;
    text-decoration: none;
}

.menu-item-wrapper span {
    color: #fff;
    text-decoration: none;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
}
.menu-item-wrapper.active {
    background-color: #EB8E13;
}