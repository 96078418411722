.header-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    align-items: center;
    border-bottom: solid 1px #FAB10D;
    margin-bottom: 10px;
    padding-bottom: 10px;
    min-height: 50px;
}

.header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.header-wrapper svg {
    width: 45px;
}

.header-wrapper span {
    color: #fff;
    font-weight: 700;
    font-size: 55px;
}

.header-wrapper-ref {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.header-wrapper-ref span {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
}

