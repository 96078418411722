.walletTable {
    width: 100%;
}
.walletTable p {
    font-size: 13px;
    text-align: center;
    margin-top: 10px;
}

.walletTable h3 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.walletTable ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    list-style-type: none;
}
.walletTable ul li {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    list-style-type: none;
}

.walletTable ul li button {
    display: flex;
    width: 100%;
    border: solid 2px #000;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding: 0 10px;
}

.walletTable ul li button span {
    display: flex;
    gap: 5px;
}

.walletTable ul li button span img {
    width: fit-content;
}

.connectText {
    color: #FAB10D;
}

.modalWrapper-content {
    background: transparent !important;
    max-width: 400px !important;
    width: 100% !important;
    padding: 0 !important;
    border: 0 !important;
}

.modalWrapper-overlay {
    background: rgba(255, 255, 255, .5);
    z-index: 999999999;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FAB10D !important;
    padding: 30px;
    gap: 10px;
    border-radius: 10px;
    margin: 0 10px;
}

.modal img {
    width: 100%;
    margin: 0 auto;
}

.modal p {
    font-size: 32px;
    color: #000;
}

.modal span {
    font-size: 20px;
}

.modal .zealyButton {
    background: #000;
    color: #fff;
    font-size: 20px;
    padding: 8px 30px;
    border-radius: 15px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 80%;
}

.modal .zealyButton svg path {
    fill: #fff;
}

.modal + .t {
    margin: 15px;
    margin-right: 15px;
    padding: 20px;
}

.modal + .t p {
    font-size: 24px;
}

.modal + .t span {
    font-size: 16px;
}

.close {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    font-size: 18px;
    width: 21px;
    height: 21px;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    border-radius: 50%;
}

.close + .t {
    top: 40px;
    right: 40px;
}


.wallet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto 0;
    color: #fff;
    gap: 20px;
    padding: 10px;
}

.wallet > p {
    margin: 0
}

.wallet h1 {
    text-align: center;
    font-size: 40px;
}

.back-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FCECAE;
    border: 1px solid #CD7000;
    width: fit-content;
    border-radius: 6px;
    padding: 8px 20px;
    text-decoration: none;
}

.back-button span {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
}

.wallet-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 7px;
    border-top: 1px solid rgba(255,255,255, .25);
    border-bottom: 1px solid rgba(255,255,255, .25);
    padding: 20px 0;
}

.wallet-address {
    color: #FCECAE;
}

.mobileLinkWallet {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    width: calc(100% - 5px);
    height: 50px;
    background-color: #fff;
    border: solid 2px #000;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding: 0 10px;
    font-size: 20px;
}

.mobileLinkWallet a {
    color: #000;
    text-decoration: none;
}