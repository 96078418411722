@import ../../helpers

.welcomeDesktopContainer
  width: calc(100% + 20px)
  position: relative
  background: $yellow1
  margin: 0 -10px
  margin-top: -10px

.not-available
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: var(--tg-viewport-stable-height)
  +t
    justify-content: flex-start

.not-available 
  img
    background: #fff
    border-radius: 15px
    max-width: 250px
    padding: 10px
    height: fit-content
    object-fit: contain
    z-index: 2
    +t
      max-width: 50%
  h2
    font-size: 50px
    text-transform: uppercase
    text-align: center
    +t
      font-size: 35px
  span
    background: $yellow2
    border: solid 1px #000
    border-radius: 30px
    font-size: 20px
    padding: 6px 30px
    margin-top: 30px
    text-align: center
    color: #000
    margin-bottom: 0
  p
    font-size: 18px
    margin-bottom: 200px

.bgFishy
  position: absolute
  bottom: 95px
  +t
    max-height: 200px
  &:last-child
    right: 0