
// media queries
=w
    @media only screen and (max-width: "1419px")
        @content
=x
    @media only screen and (max-width: "1280px")
        @content
=d
    @media only screen and (max-width: "1000px")
        @content
=i
    @media only screen and (max-width: "1076px")
        @content
=t
    @media only screen and (max-width: "834px")
        @content
=m
    @media only screen and (max-width: "767px")
        @content
=a
    @media only screen and (max-width: "639px")
        @content
=s
    @media only screen and (max-width: "474px")
        @content

=xs
    @media only screen and (max-width: "380px")
        @content
    
// colors
$yellow1: rgba(250, 177, 13, 1)
$yellow2: rgba(252, 236, 174, 1)
$yellow3: rgba(244, 119, 48, 1)
$yellow4: rgba(248, 161, 48, 1)
$white: #FFF
$r-border: 40px
$border-1: solid 1px $white
$border-2: solid 2px $white

