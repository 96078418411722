.page-task
    color: #fff
    padding: 10px
    padding-top: 40px
    h2
        font-size: 30px
        margin: 10px 0
    p
        margin: 0
    svg
        height: 100%
        max-height: 40px

.reward-wrapper
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 5px
    width: calc( 100% - 20px )
    background: #CD7000
    border-radius: 10px
    border: solid 1px #FAB10D
    padding: 10px
    margin: 15px 0


.reward-text-wrapper
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    gap: 3px

.tasks-wrapper
    display: flex
    flex-direction: column
    gap: 7px
    padding: 0

.task-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    gap: 5px
    width: calc( 100% - 30px )
    background: #CD7000
    border-radius: 10px
    border: solid 1px #FAB10D
    padding: 10px
    padding-left: 20px
    font-size: 16px
    button
        background: #FCECAE
        border: 0
        color: #EB8E13
        font-size: 16px
        border-radius: 10px
        padding: 8px 25px
        height: 36px
        min-width: 93px