.tab-container {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10px);
    background-color: #FCECAE;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 4px 0 #CD7000;
    margin-top: 5px;
}

.tab-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0;
    width: calc(100%/3 - 5px);
    border-radius: 6px;
    padding: 5px 0;
    text-decoration: none;
}
.tab-item-wrapper span {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
}

.task-item-wrapper.active {
    background-color: #EB8E13;
}

.tasks-wrapper {
    color: #fff;
    padding: 20px 0;
    text-align: center;
}