.bar-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px
}
.bar-text {
    background: #FCECAE;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    height: 40px;
    width: 130px;
    padding: 2px;
}
.big-text {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
}
.small-text {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
}

.bar-wrapper {
    background-color: #FAB10D;
    box-shadow: 0 0 10px #FAB10D;
    border-radius: 15px;
    width: 100%;
    height: 28px;
    position: relative;
    overflow: hidden;
}

.inner-bar {
    background-color: #FCECAE;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 15px;
}