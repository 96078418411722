@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');

body {
  margin: 0;
  font-family: "Acme", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  background: #EB8E13;
  margin: 0 auto;
  overflow: hidden;
  height: calc(var(--tg-viewport-stable-height) - 20px);
}

* {
  font-family: "Acme", sans-serif !important;
}

*:focus {
  outline: none;
}

/* Add additional styles for other elements as needed */
