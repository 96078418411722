.modal-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: rgba(185, 92, 0, 0.7);
}

.modal-container {
    background: rgba(235, 142, 19, 1);
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
    padding: 10px;
    padding-top: 50px;
    border-radius: 26px 26px 0 0;
    min-height: 50vh; /* Set a minimum height, adjust as needed */
    display: flex; /* Ensure flex properties work */
    flex-direction: column; /* Stack children vertically */
}

.modal-container-ref {
    background: rgba(235, 142, 19, 1);
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
    padding: 10px;
    padding-top: 50px;
    border-radius: 26px 26px 0 0;
    min-height: 25vh; /* Set a minimum height, adjust as needed */
    display: flex; /* Ensure flex properties work */
    flex-direction: column; /* Stack children vertically */
}

.close-modal {
    background: transparent;
    border: 0;
    position: absolute;
    right: 10px;
    top: 20px;
}

.slide-area {
    background: transparent;
    border: 0;
    position: absolute;
    top: 20px;
    left: calc(50% - 38px);
}

.content-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    padding-top: 20px;
    padding-bottom: 20px;
}

.content-inner-wrapper-ref {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 15px
}

.content-inner-wrapper p {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin: 0;
}

.get-it-button {
    width: 100%;
    background: rgba(252, 236, 174, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 10px;
    height: 64px;
    color: rgba(235, 142, 19, 1);
    text-decoration: none;
    font-size: 24px;
    font-family: "Acme", sans-serif;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
    box-shadow: 0 4px 0 #CD7000;
    cursor: pointer;
}

.ref-share-button {
    width: 100%;
    background: rgba(252, 236, 174, 1);
    border: 0;
    border-radius: 10px;
    height: 40px;
    color: rgba(235, 142, 19, 1);
    text-decoration: none;
    font-size: 20px;
    font-family: "Acme", sans-serif;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
    box-shadow: 0 4px 0 #CD7000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-title {
    text-align: center;
    font-size: 48px;
    color: #fff;
}

.main-modal-image {
    background: rgba(252, 236, 174, 1);
    border-radius: 12px;
    padding: 4px;
    margin: 20px;
    width: 20vw;
    margin-bottom: 0;
}

.inner-text {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.price-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    color: #fff;
    gap: 5px;
    font-size: 35px;
}