.referral-container {
    display: flex;
    justify-content: space-between;
    width: 100%; 
    margin-top: 5px;
}

.referral-container span {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.invite-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FCECAE;
    border: 0;
    width: calc(100%/3 - 5px);
    border-radius: 6px;
    padding: 8px 0;
    text-decoration: none;
}

.invite-button span {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
}

.tab-item-wrapper.active {
    background-color: #EB8E13;
}

.ref-wrapper {
    color: #fff;
    padding: 20px 8px;
    text-align: center;
}