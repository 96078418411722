@import ../../../helpers

header
    background-color: transparent
    position: relative
    z-index: 99999s
    width: calc( 100% - 20px )
    padding: 0 10px !important
    +t
        width: calc( 100% - 20px )


.wrapper
    margin: 10px auto
    max-width: 1440px
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    background: $yellow2
    border-radius: 45px
    border: solid 2px #000
    +t
        justify-content: center

.menu
    display: flex
    justify-content: center
    align-items: center
    &>a>img
        height: 100%
        display: block
    +t
        justify-content: flex-start
        align-items: flex-start
        width: 100%
    ul
        display: flex
        flex-direction: row
        gap: 20px
        margin-left: 35px
        margin-right: 18px
        li
            display: flex
            justify-content: center
            align-items: center
        a
            display: flex
            justify-content: center
            align-items: center
            text-decoration: none
            color: #000
            gap: 5px
            font-weight: 400
            font-size: 22px
            line-height: 143.7%
            transition: .3s
            &:hover
                opacity: .8
            svg
                height: 35px
            +x
                font-size: 1.5vw
        +t
            display: none

.headerWhitePaperLink
    border: $border-2
    border-radius: $r-border
    padding: 12px 30px
    font-style: normal
    font-weight: 500
    font-size: 18px
    line-height: 158.7%
    letter-spacing: -0.03em
    transition: .3s
    &:hover
        background: #ffffff
        color: #0D5590


.dashboard-button
    +t
        display: none

.mobile-burger
    display: none
    +t
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        right: 42px
        top: 30px
        cursor: pointer
        z-index: 6
        width: 35px
        height: 35px
        border: none
        background: transparent

.mobile-menu
    position: fixed
    top: 0
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    width: 100%
    height: 100%
    z-index: 5
    background: $yellow2
    display: flex
    flex-direction: column
    justify-content: flex-start
    padding-top: 16px
    padding-left: 20px
    padding-right: 20px
    ul
        list-style-type: none !important
        padding-left: 0
        a
            text-decoration: none
            color: #000
    
.mobile-menu-socials
    display: flex
    flex-direction: row
    width: calc(100% - 80px)
    margin: 0 40px
    justify-content: space-between

.mobile-menu-social-wrapper
    justify-self: flex-end
    margin-top: auto
    padding-bottom: 50px
    width: calc( 100% - 40px )

.mobile-menu .specialFont
    font-family: 'Fredoka One', cursive
    text-transform: uppercase
    -webkit-text-strokeWidth: 1px
    -webkit-text-stroke-color: #fff
    color: transparent
    padding-bottom: 20px
    padding-left: 10px
    font-weight: heavy
    font-size: 24px
    line-height: 32px
    text-align: left
    display: block

.mobile-menu-list
    padding: 10px 0
    a
        font-size: 35px

.socials-title
    text-align: center !important
    border-bottom: 0 !important
    margin-bottom: 0 !important

.mobile-menu-wrapper
    overflow-y: auto
    padding: 30px 10px

.mobile-menu-small
    margin-bottom: 25px
    padding-bottom: 25px

.mobile-menu-social-wrapper a
    font-size: 26px

.airdropButton
    position: relative
    a
        color: $yellow1
        background: #000
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        gap: 5px
        border-radius: 20px
        padding: 5px 15px
        white-space: nowrap
        span
            font-size: 20px
            color: $yellow1
            +t
                font-size: 28px