.boost-container, .upgrade-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 0;
    padding-top: 10px;
}
.boost-item {
    background: transparent;
    cursor: pointer;
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(252, 236, 174, 0.5);
    border: solid 1px #FAB10D;
    border-radius: 8px;
    width: calc(50% - 5px);
    padding: 5px 10px;
    gap: 7px;
}

.boost-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #fff;
    text-decoration: none;
    text-align: left;
    gap: 2px;
    font-size: 14px;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
}

.booster-title, .upgrade-title {
    width: 100%;
    color: #fff;
    font-size: 18px;
    display: block;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.upgrade-item {
    background: transparent;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    background-color: #CD7000;
    border: solid 1px #FAB10D;
    border-radius: 8px;
    width: 100%;
    padding: 5px 10px;
    gap: 7px;
    min-height: 65px;
}

.upgrade-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 7px
}

.upgrade-text-wrap {
    display: flex;
    gap: 5px;
}

.upgrade-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: #fff;
    font-size: 16px;
    font-family: "Acme", sans-serif;
    text-shadow: 0px -1px #CD7000, 1px 0px #CD7000, 0px 1px #CD7000, -1px 0px #CD7000,  0 3px 3px #cd710069;
}

.iconContainerBoost {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coming {
    text-transform: uppercase;
    font-size: 10px;
    color: #FAB10D;
    margin-bottom: 10px;
}