.tasks-container {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    gap: 5px;
    justify-content: space-between;
}

.tasks-container .task-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #CD7000;
    border: solid 1px #FAB10D;
    border-radius: 8px;
    width: calc(100% - 20px);
    padding: 10px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.tasks-container .task-container.disabled {
    pointer-events: none;
}

.tasks-container .task-left {
    display: flex;
    gap: 5px
}

.tasks-container .price-wrapper svg {
    height: 19px;
    width: 19px;
}

.tasks-container .price-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2px;
}

.tasks-container .price-wrapper .price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    gap: 6px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}
.completedMark {
    color: #FAB10D;
    display: flex;
    align-items: center;
}